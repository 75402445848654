<template>
  <div
    class="sidebar"
    :data-color="activeColor"
    :data-background-color="backgroundColor"
  >
    <div class="logo">
      <div @click="irInicio" class="logo-img">
        <img :src="logo" />
      </div>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <md-list class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
export default {
  name: "sidebar",
  props: {
    activeColor: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "black",
    },
    logo: {
      type: String,
      default: "/img/OutBox_LogoHoriz_White_mini.png",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    irInicio() {
      this.$router.push({
        name: "Início0",
      });
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
.logo-img {
  justify-content: center;
  display: flex;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
